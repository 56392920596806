/* storybook-check-ignore */

import { Image } from '@opendoor/bricks/core';
import { RadiiProps } from '@opendoor/bricks/system/theme';
import Head from 'next/head';

import { breakpoints } from '@opendoor/bricks/theme/novo/foundations/breakpoints';

export type PictureSourceSpec = Array<{
  srcSet: string;
  media: string;
  key: string;
}>;

export const Picture: React.FC<{
  preload: boolean;
  lazy: boolean;
  radii: RadiiProps;
  sources: PictureSourceSpec;
}> = ({ preload, lazy, radii, sources }) => {
  return (
    <>
      {preload && (
        <Head>
          {sources.map(({ srcSet, media, key }) => {
            return (
              <link
                rel="preload"
                as="image"
                href=""
                // @ts-expect-error: react incorrectly capitalizes this if you use
                // the "typescript" suggestion of `imgSrcSet`
                imagesrcset={srcSet}
                media={media}
                key={`${key}-preload`}
                fetchpriority="high"
              />
            );
          })}
        </Head>
      )}
      <picture>
        {sources.map(({ srcSet, media, key }) => {
          return <source srcSet={srcSet} media={media} key={`${key}-source`}></source>;
        })}
        {/* Intentionally missing src, so we don't double load images */}
        <Image
          objectFit="cover"
          width={`100%`}
          height={`100%`}
          alt=""
          {...radii}
          loading={lazy ? 'lazy' : undefined}
        />
      </picture>
    </>
  );
};

export type PresetPictureSet = Array<{
  minWidth: number;
  maxWidth: number | null;
  preset: string;
  presetScale?: string;
}>;
export type PresetPictureSetOptions = {
  photoUrl: string;
  sizes: PresetPictureSet;
  radii: RadiiProps;
  debugImageSizes: boolean;
  lazy: boolean;
  dpr?: Array<number> | null;
  preload?: boolean;
};
export const generatePresetPictureSet = ({
  photoUrl,
  sizes,
  radii,
  lazy,
  dpr = [1, 2, 3],
  preload = false,
}: PresetPictureSetOptions) => {
  // sort in reverse because <source> matches in order, required to use
  // the min-width selectors
  const sortedSizes = sizes.sort((a, b) => (a.minWidth <= b.minWidth ? -1 : 1));
  const sources: Array<{ srcSet: string; media: string; key: string }> = sortedSizes.map(
    ({ minWidth, maxWidth, preset, presetScale }) => {
      const paramsRecord: Record<string, string> = {
        preset,
        ...(presetScale ? { presetScale } : {}),
        service: 'cosmos',
      };
      const srcSet =
        dpr && dpr?.length > 0
          ? dpr
              .map((d) => {
                const finalParams: Record<string, string> = { ...paramsRecord, dpr: `${d}` };
                return `${photoUrl}?${new URLSearchParams(finalParams).toString()} ${d}x`;
              })
              .join(', ')
          : `${photoUrl}?${new URLSearchParams(paramsRecord).toString()}`;
      const media =
        maxWidth === null
          ? `(min-width: ${minWidth}px)`
          : `(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`;
      return { srcSet, media, key: `${photoUrl}-${minWidth}` };
    },
  );
  return <Picture key={photoUrl} preload={preload} lazy={lazy} radii={radii} sources={sources} />;
};

export type DeprecatedPictureSizeSet = Array<{
  minWidth: number;
  maxWidth: number | null;
  pictureBasisWidth: number;
  containingElementHeightPercent: number;
  containingElementWidthPercent: number;
  baselineHeight: number;
}>;

export type DeprecatedHeroSrcSetOptions = {
  photoUrl: string;
  sizes: DeprecatedPictureSizeSet;
  radii: RadiiProps;
  debugImageSizes: boolean;
  lazy: boolean;
  dpr?: Array<number> | null;
  preload?: boolean;
  auto?: string | null;
  ch?: string | null;
};

export const generateDeprecatedImgixPictureSet = ({
  photoUrl,
  sizes,
  radii,
  debugImageSizes,
  lazy,
  dpr = [1, 2, 3],
  preload = false,
  auto = 'format,compress',
  ch = 'Width,DPR',
}: DeprecatedHeroSrcSetOptions) => {
  // sort in reverse because <source> matches in order, required to use
  // the min-width selectors
  const sortedSizes = sizes.sort((a, b) => (a.minWidth <= b.minWidth ? -1 : 1));
  const sources: Array<{ srcSet: string; media: string; key: string }> = sortedSizes.map(
    ({
      minWidth,
      maxWidth,
      pictureBasisWidth,
      containingElementHeightPercent,
      containingElementWidthPercent,
      baselineHeight,
    }) => {
      const paramsRecord: Record<string, string> = {
        ...(auto && { auto }),
        ...(ch && { ch }),
        w: `${Math.floor(pictureBasisWidth * containingElementWidthPercent * 0.01)}`,
        h: `${Math.floor(baselineHeight * containingElementHeightPercent * 0.01)}`,
      };
      const srcSet =
        dpr && dpr?.length > 0
          ? dpr
              .map((d) => {
                const finalParams: Record<string, string> = { ...paramsRecord, dpr: `${d}` };
                if (debugImageSizes) {
                  finalParams[
                    'txt'
                  ] = `w=${finalParams['w']}&h=${finalParams['h']}&dpr=${finalParams['dpr']}`;
                  finalParams['txt-align'] = 'middle,center';
                  finalParams['txt-color'] = 'FFFFFF';
                  finalParams['txt-size'] = '24';
                }
                return `${photoUrl}?${new URLSearchParams(finalParams).toString()} ${d}x`;
              })
              .join(', ')
          : `${photoUrl}?${new URLSearchParams(paramsRecord).toString()}`;
      const media =
        maxWidth === null
          ? `(min-width: ${minWidth}px)`
          : `(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`;
      return { srcSet, media, key: `${photoUrl}-${minWidth}` };
    },
  );
  return <Picture key={photoUrl} preload={preload} lazy={lazy} radii={radii} sources={sources} />;
};

export function generateDeprecatedPictureSizeSet(
  responsiveDimensions: Array<{ w: number; h: number }>,
): DeprecatedPictureSizeSet {
  return [
    {
      minWidth: breakpoints[0],
      maxWidth: breakpoints[1],
      pictureBasisWidth: responsiveDimensions[0].w,
      containingElementWidthPercent: 100,
      containingElementHeightPercent: 100,
      baselineHeight: responsiveDimensions[0].h,
    },
    {
      minWidth: breakpoints[1],
      maxWidth: breakpoints[2],
      pictureBasisWidth: responsiveDimensions[1].w,
      containingElementWidthPercent: 100,
      containingElementHeightPercent: 100,
      baselineHeight: responsiveDimensions[1].h,
    },
    {
      minWidth: breakpoints[2],
      maxWidth: breakpoints[3],
      pictureBasisWidth: responsiveDimensions[2].w,
      containingElementWidthPercent: 100,
      containingElementHeightPercent: 100,
      baselineHeight: responsiveDimensions[2].h,
    },
    {
      minWidth: breakpoints[3],
      maxWidth: breakpoints[4],
      pictureBasisWidth: responsiveDimensions[3].w,
      containingElementWidthPercent: 100,
      containingElementHeightPercent: 100,
      baselineHeight: responsiveDimensions[3].h,
    },
    {
      minWidth: breakpoints[4],
      maxWidth: null,
      pictureBasisWidth: responsiveDimensions[4].w,
      containingElementWidthPercent: 100,
      containingElementHeightPercent: 100,
      baselineHeight: responsiveDimensions[4].h,
    },
  ];
}
