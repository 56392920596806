/* storybook-check-ignore */
import styled from '@emotion/styled';
import { colors } from '@opendoor/bricks/theme/eero';

import React, { useState } from 'react';
import { useTrackableLink } from './trackers';
import { DEFAULT_NAV_MENU_BUTTON_ID, DEFAULT_NAV_MENU_ID } from './MenuPanel';
import { COSMOS_URL } from '../globals';
import { useObservability } from '../../helpers/observability';
import Box from '@opendoor/bricks/core/Box';
import { Link } from '@opendoor/bricks/core';
import { useAuth } from '@opendoor/auth-fe';
import { EiChevronDownIcon } from './EiChevronDownIcon';
import AccountMenu from '../account/AccountMenu';

const IconWrapper = styled.div`
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-bottom: 0.2em;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
`;
const MenuButton = styled.button`
  border: 0;
  height: 27px;
  width: 27px;
  margin-right: 15px;
  cursor: pointer;
  float: left;
  background: transparent url('https://imgdrop.imgix.net/06fcecd57df4411b9f7448f24fbff233.svg')
    center no-repeat;
  padding: 0;
`;

const Middle = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const Nav = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  :before,
  :after {
    display: table;
    content: ' ';
  }

  :after {
    clear: both;
  }
`;

const NavUL = styled.ul`
  display: inline-block;
  margin: 0;
  list-style: none;
  padding: 0;
`;

interface INavProps {
  active?: boolean;
}

const NavLI = styled.li`
  display: inline-block;
  margin: 0 10px;
`;

const activeNavLinkStyle = (props: INavProps) => {
  if (props.active) {
    return `color: ${colors.brand50};`;
  }
  return '';
};

const NavLIA = styled.a<INavProps>`
  color: #525975;
  ${activeNavLinkStyle};
`;

const Wrapper = styled.header`
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  box-shadow: 0px 3px 40px rgba(68, 89, 109, 0.1);
`;

const Container = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;
`;

const Right = styled.div`
  flex: 1;
  text-align: right;
`;

export type NavLinks = Array<{
  name: string;
  href: string;
}>;

const NavLink: React.FC<{ href: string; elementName?: string; active?: boolean }> = (props) => {
  const { trackEvent } = useObservability();
  const [ref, doTrackedClick] = useTrackableLink(props.href, 'header', props.elementName);
  const onClickFn = (e: any) => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', props.elementName, undefined, { categoryOverride: 'homepage' });
    doTrackedClick(e);
  };
  return (
    <NavLIA
      onClick={onClickFn}
      ref={ref}
      href={props.href}
      active={props.active}
      aria-current={props.active}
    >
      {props.children}
    </NavLIA>
  );
};

const SkipLinkContainer = styled.button({
  alignItems: 'center',
  backgroundColor: colors.neutrals0,
  border: 0,
  bottom: 0,
  color: colors.brand50,
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  outline: 0, // text decoration will communicate focus
  position: 'absolute',
  top: 0,
  transform: 'translateX(-100%)',
  width: '100%',
  zIndex: 1,

  '&:focus': {
    textDecoration: 'underline',
    transform: 'translateX(0)',
  },
});

const SkipLink: React.FC = (_props) => {
  return (
    <SkipLinkContainer
      onClick={() => {
        // there should only be 1 main on any page and it should wrap the main content
        const mainElem = document.querySelector('main');
        mainElem && mainElem.focus();
      }}
    >
      Skip to main content
    </SkipLinkContainer>
  );
};

export type HeaderNavLinkName = 'sell' | 'buy' | 'buy-and-sell';

interface IProps {
  activeNav?: HeaderNavLinkName;
  extraNavLinks?: NavLinks;
  hideHeaderNavLinks?: boolean;
  isMenuOpen: boolean;
  navMenuButtonId?: string;
  navMenuId?: string;
  toggleMenuOpen: () => void;
}
const redirectToLogin = () => {
  document.location.href = `${COSMOS_URL}/login`;
};

const Header: React.FC<IProps> = (props) => {
  const {
    activeNav = 'sell' as HeaderNavLinkName,
    extraNavLinks,
    hideHeaderNavLinks,
    isMenuOpen = false,
    navMenuButtonId = DEFAULT_NAV_MENU_BUTTON_ID,
    navMenuId = DEFAULT_NAV_MENU_ID,
    toggleMenuOpen = () => {},
  } = props;

  const { trackEvent } = useObservability();
  const [isExpanded, setExpanded] = useState(false);
  const { user: human, authentication } = useAuth();

  const isLoggedIn = authentication.state === 'authenticated';
  const handleMenuClick = () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'menu', undefined, { categoryOverride: 'homepage' });
    toggleMenuOpen();
  };
  const handleAccountClick = () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'account-icon', undefined, { categoryOverride: 'homepage' });
    authentication.state === 'authenticated' ? setExpanded(!isExpanded) : redirectToLogin();
  };
  const fullName = human?.fullName;
  const firstName = fullName ? fullName.split(' ')[0] : '';

  return (
    <Wrapper>
      <SkipLink />
      <Container>
        <Left>
          <MenuButton
            aria-controls={navMenuId}
            aria-expanded={isMenuOpen ? 'true' : 'false'}
            aria-haspopup="true"
            aria-label="Navigation menu"
            id={navMenuButtonId}
            onClick={handleMenuClick}
          />
          <img
            src="https://imgdrop.imgix.net/78cd98232496454f991c20be2d28dc01.svg"
            alt="Opendoor"
            height={27}
            width={135}
          />
        </Left>
        <Middle>
          {!hideHeaderNavLinks && (
            <Nav>
              <NavUL>
                <NavLI>
                  <NavLink active={activeNav === 'sell'} href="/" elementName="homepage">
                    Sell
                  </NavLink>
                </NavLI>
                <NavLI>
                  <NavLink active={activeNav === 'buy'} href="/homes" elementName="homes">
                    Buy
                  </NavLink>
                </NavLI>
                <NavLI>
                  <NavLink
                    active={activeNav === 'buy-and-sell'}
                    href="/buy-and-sell"
                    elementName="buy-and-sell"
                  >
                    Buy & Sell
                  </NavLink>
                </NavLI>
                {(extraNavLinks || []).map((nav) => {
                  return (
                    <NavLI key={nav.name}>
                      <NavLink href={nav.href}>{nav.name}</NavLink>
                    </NavLI>
                  );
                })}
              </NavUL>
            </Nav>
          )}
        </Middle>
        <Right onClick={handleAccountClick}>
          {isLoggedIn ? (
            <Box cursor="pointer" fontWeight="semibold">
              Welcome {firstName} &nbsp;
              <IconWrapper>
                <EiChevronDownIcon />
              </IconWrapper>
              <AccountMenu isExpanded={isExpanded} />
            </Box>
          ) : (
            <Link href={'#'} fontWeight="semibold" analyticsName="cosmos-sign-in" aria-label="">
              Sign In
            </Link>
          )}
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Header;

// since the header is implemented as a fixed position element, we need to
// add a placeholder element to the page to prevent the content from jumping
// when the return experience banner is added into the DOM
export const HeaderPlaceholder = styled.div`
  height: 55px;
`;
